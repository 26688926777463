import { onMounted, defineComponent, reactive, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { setToken } from '@/utils/token';
import { doCallPhone, doSendSms, doPayDeposit, doPayCall, getContactPrice, getDepositAmount } from '@/api/user';
import { currentClient } from '@/utils/until';
import { Toast, Dialog } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import wx from 'weixin-js-sdk';
export default defineComponent({
  name: 'Scan2',
  components: {},

  setup() {
    const state = reactive({
      code_sn: '',
      // 二维码编号
      license_plate_number: '',
      // 车牌号
      is_auth: '',
      // 是否授权
      need_auth: false,
      // 是否需要授权
      need_pay: false,
      // 是否需要支付
      contact_price: 0,
      client: currentClient(),
      // 当前运行环境
      // client: 'wechat', // 当前运行环境
      showOverlay: false,
      depositAmount: 100,
      showOverlayPaySuccess: false,
      // 支付成功弹窗
      has_phone: false,
      // 是否绑定手机号
      user_number: '',
      expire_min: '',
      need_refund_msg: false // 需要订阅退款通知

    });
    const route = useRoute();
    const router = useRouter();
    onMounted(async () => {
      const toast = Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      await setTokenInit();
      const query = route.query;
      const data = JSON.parse(query.data);
      state.code_sn = data.sn;
      state.license_plate_number = data.license_plate_number;
      state.is_auth = data.is_auth;
      state.need_auth = data.need_auth;
      state.need_pay = data.need_pay || 0;
      const paySuccess = data.pay || false;
      state.has_phone = data.has_phone || false;
      state.user_number = data.user_number;
      state.expire_min = data.expire_min;
      state.need_refund_msg = data.need_refund_msg || false;

      if (paySuccess && data.is_auth) {
        // 支付回调弹窗
        state.showOverlayPaySuccess = true;
      }

      if (state.need_pay) {
        let {
          data
        } = await getContactPrice();
        state.contact_price = data.price;
      }

      toast.clear();
    });

    const setTokenInit = () => {
      return new Promise(resolve => {
        let query = route.query;
        const token = query.token || '';

        if (token) {
          setToken(query.token);
        }

        resolve();
      });
    };
    /**
     * 关闭弹窗
     */


    const closeOverlay = () => {
      state.showOverlay = false;
    };
    /**
     * 获取金额
     */


    const onAuth = async () => {
      if (state.client !== 'wechat') {
        return false;
      }

      const {
        data
      } = await getDepositAmount({
        sn: state.code_sn
      });
      state.depositAmount = data.amount;
      state.showOverlay = true;
    };
    /**
     * 支付
     */


    const onAuthPay = async () => {
      const toast = Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      const sn = state.code_sn; // 挪车码编号

      const {
        data,
        status,
        msg
      } = await doPayDeposit({
        sn
      });
      toast.clear();

      if (status != 1) {
        return Toast.fail(msg);
      }

      wx.miniProgram.postMessage({
        data: {
          payment: data,
          msg: '剩余金额将在3个工作日内自动退回钱包',
          type: 'scan',
          sn
        }
      });
      wx.miniProgram.reLaunch({
        url: '/pages/payment/index'
      });
    };
    /**
     * 拨打电话
     */


    const onCallPhone = async () => {
      Dialog.confirm({
        title: '提示',
        message: '为保护双方隐私，以虚拟号安全通话'
      }).then(async () => {
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true
        });
        const {
          data,
          msg,
          status
        } = await doCallPhone({
          code_sn: state.code_sn
        });
        toast.clear();

        if (status != 1) {
          Toast(msg);
        } else {
          const a = document.createElement('a');
          a.href = 'tel:' + data.tel;
          a.click();
        }
      }).catch(() => {});
    };
    /**
     * 拨打电话
     * 跳转小程序页面
     */


    const onCallPhoneNvi = () => {
      wx.miniProgram.navigateTo({
        url: '/pages/scan/phone?code_sn=' + state.code_sn + '&has_phone=' + state.has_phone + '&user_number=' + state.user_number + '&expire_min=' + state.expire_min + '&need_refund_msg=' + state.need_refund_msg
      });
    };
    /**
     * 支付后呼叫车主
     */


    const payCall = async () => {
      const toast = Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      let code_sn = state.code_sn;
      const {
        data,
        status
      } = await doPayCall({
        code_sn
      });
      toast.clear();

      if (status == 1) {
        wx.miniProgram.postMessage({
          data: {
            payment: data,
            msg: '已支付!',
            type: 'scan',
            sn: code_sn
          }
        });
        wx.miniProgram.reLaunch({
          url: '/pages/payment/index'
        });
      }
    };
    /**
     * 发送短信通知
     */


    const onSendMessage = async () => {
      const toast = Toast.loading({
        message: '发送中...',
        forbidClick: true
      });
      const {
        msg,
        status
      } = await doSendSms({
        code_sn: state.code_sn
      });
      toast.clear();

      if (status == 1) {
        Toast.success('已通知车主');
      } else {
        Toast.fail(msg);
      }
    };
    /**
     * 商城
     */


    const goShop = () => {
      wx.miniProgram.reLaunch({
        url: '/pages/web/index?name=shop'
      });
    };
    /**
     * 个人中心
     */


    const goUser = () => {
      router.push({
        name: 'User'
      });
    };

    const handleUserService = () => {
      window.open('https://ncmweb.imxiandian.com/useragreement240825.html');
    };

    return { ...toRefs(state),
      onCallPhone,
      onCallPhoneNvi,
      onSendMessage,
      onAuth,
      payCall,
      goShop,
      goUser,
      closeOverlay,
      onAuthPay,
      handleUserService,
      setTokenInit
    };
  }

});